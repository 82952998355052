.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  z-index: 1000;
  transition: opacity 1s, visibility 1s;
}

.loader-planet {
  height: 200px;
  position: absolute;
  animation: loading ease 3s infinite;
}

.loader-planet-2 {
  height: 350px;
}

.loader--hidden {
  opacity: 0;
  visibility: hidden;
}

.loader::after {
  content: "";
  width: 100px;
  height: 100px;
  background-color: white;

  border-radius: 50%;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
