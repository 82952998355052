.about-img-container {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 200px;
}

.about-text-container {
  height: 20vh;
  display: flex;
  flex-direction: column;
  margin: 200px 0 0 20px;
}

.about-img {
  height: 400px;
  object-fit: cover;
  width: 400px;
}

.about-main-container {
  background-color: var(--dark-color);
}

.about-title {
  color: var(--light-color);
  font-family: var(--base-font-family);
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 20px;
  font-size: 35px;
}

.about-text {
  color: var(--light-color);
  font-family: transducer, sans-serif;
  font-size: 20px;
}

@media screen and (max-width: 1000px) {
  .about-img-container {
    height: 50vh;
  }

  .about-text-container {
    height: 50vh;
    justify-content: center;

    margin: 40px 20px 0 20px;
  }

  .about-img {
    height: 40vh;
    width: 40%;
  }
  .about-img-container {
    height: 40vh;
    width: 100%;
    display: block;
    justify-content: none;
    align-items: none;
    margin-left: 20px;
    margin-bottom: 100px;
    margin-top: 0;
  }
}
