.contact-container {
  color: var(--light-color);
  background-color: var(--dark-color);
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--h-font-family);
  font-size: 10px;
  padding-top: 180px;
  flex-direction: column;
}

.contact-title {
  margin-bottom: 10px;
}
