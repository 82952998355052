/* ====================================
// css variables
// ==================================== */

:root {
  /* Default */
  --spacing-unit: 15px; /* unité qui va déterminer les espaces principalement verticaux */
  --gutter-unit: 15px; /* unité des gouttières */
  --scale-unit: 1.25; /* unité d'échelle */

  /* Color */
  --dark-color: #000;
  --light-color: #fff;
  --grey-color: #d9d9d9;
  --brand-color: #d7da00;
  --accent-color: #0300da;

  /* Font */
  --base-font-family: "transducer-extended", sans-serif; /* font du texte courant */
  --base-font-size: 16px; /* taille du texte par défaut */
  --base-font-weight: 400; /* graisse par défaut */
  --bold-font-weight: 700; /* graisse des caractères gras */
  --base-line-height: 1.5; /* interlignage par défaut */
  --small-font-size: 12px; /* petite taille de texte */
  --h-font-family: "Zen Dots", cursive; /* font des titres headings */
  --h1-font-size: 20px; /* taille du h1 */
  --h2-font-size: 30px; /* taille du h2 */
  --h3-font-size: 25px; /* taille du h3 */
  --h-line-height: 1.2; /* interlignage des titres */
}

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  cursor: none;
  scroll-behavior: smooth;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overscroll-behavior: none;
  font-size: 1rem;
  line-height: 1.25;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

/* video {
  cursor: auto;
} */

/* ====================================
// base rules
// ==================================== */
