@import "./_base.css";
@import "./_layout.css";
@import "./modules/_content.css";
@import "./modules/_nav.css";
@import "./modules/_about.css";
@import "./modules/_contact.css";
@import "./modules/_pictures.css";
@import "./modules/_movie.css";
@import "./modules/_loader.css";
@import "./bootstrap.scss";
