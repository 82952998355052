.content-main-container {
  background-color: var(--dark-color);
}

.content-video-container-left-top {
  height: 50vh;
  width: 100%;
  transition: all 0.3s;
}

.content-video-container-small {
  overflow: hidden;
}

.content-link-a {
  position: relative;
}

.content-video-title-date-container {
  font-family: var(--base-font-family);
  font-weight: 600;
  text-transform: uppercase;
  color: var(--light-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 22px;
  opacity: 0;
  transition: all 0.3s;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  pointer-events: none;
}

.content-video-content {
  position: relative;
}

.content-video-container-player {
  opacity: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: all 0.3s;
}
.content-video-container-player:hover {
  opacity: 0.5;
}

.content-video-content:hover .content-video-title-date-container {
  opacity: 1;
}

.nav-home-btn:hover .nav-cursor {
  height: 100px;
  width: 100px;
}

.content-video-container-small {
  background-size: cover;
  height: 50vh;
  width: 100%;
}

.content-video-container-large {
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.content-container-bottom-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-btn-container {
  font-family: var(--h-font-family);
  text-transform: uppercase;
  color: var(--light-color);
  display: inline-block;
  border: 4px solid var(--light-color);
  padding: 20px 40px 20px 40px;
  border-radius: 50px;
  margin-bottom: 150px;
  transition: all 0.35s;
}
.content-btn-container:hover {
  background-color: var(--light-color);
  color: var(--dark-color);
}

.content-footer-btn-container {
  font-family: var(--h-font-family);
  text-transform: uppercase;
  color: var(--light-color);
  /* display: flex; */
  /* justify-content: space-between; */
}

/* .content-footer-container-box {
  margin: 0 40px 40px 40px;
}

.content-footer-title {
  margin: 40px 0 30px 0;
}

.content-footer-link {
  font-size: 10px;
} */

.content-footer-container {
  background-color: var(--light-color);
}

.content-footer-container-video {
  background-color: var(--dark-color);
}

.content-seperation-middle {
  border-right: solid 4px var(--light-color);
  height: 200px;
}

.content-copyright-text {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-family: var(--base-font-family);
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--light-color);
  margin-top: 120px;
  margin-bottom: 20px;
  z-index: 1;
}
.content-copyright-text:hover {
  text-transform: lowercase;
}

.content-footer-container-box-video {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Homepage */

.content-main-container-homepage {
  background-color: var(--light-color);
}

.content-body-homepage {
  background-color: var(--light-color);
}

.content-copyright-text-homepage {
  color: var(--dark-color);
  position: relative;
}

.content-welcome-container-homepage {
  height: 100vh;
  margin-bottom: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 100px;
}

.content-welcome-title {
  font-family: var(--base-font-family);
  font-weight: 600;
  font-size: 60px;
  text-transform: uppercase;
  position: absolute;
  margin-bottom: 10px;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  text-align: center;
}

.content-welcome-image {
  height: 80%;
  z-index: 1;
}

.content-welcome-images-container {
  margin-left: 20px;
}

.content-welcome-title-images {
  font-family: var(--base-font-family);
  font-weight: 600;
  font-size: 30px;
  text-transform: uppercase;
  z-index: 1;
  text-align: left;
  margin: 20% 60px 20px 20px;

  /* display: inline-block;
  padding: 40px 50px;
  border-radius: 100px;
  color: white;
  background-color: blue;
  animation: ANima 30s infinite; */
}
/* .content-welcome-title-images:hover {
  background-color: red;
} */

/* @keyframes ANima {
  0% {
    top: 30px;
    left: 10px;
    transform: rotate(10deg);
  }
  25% {
    top: 180px;
    left: 140px;
    transform: rotate(100deg);
  }
  50% {
    top: 300px;
    left: 0;
    transform: rotate(-100deg);
  }
  75% {
    top: 200px;
    left: 200px;
    transform: rotate(40deg);
  }
  100% {
    top: 30px;
    left: 10px;
    transform: rotate(10deg);
  }
} */

.content-welcome-container-left {
  padding-left: 20px;
}

.content-welcome-title-text {
  font-family: var(--base-font-family);
  font-weight: 300;
  font-size: 30px;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  text-align: right;
  margin-right: 60px;
}

.content-welcome-video-containers {
  width: 100%;
  height: 50vh;
  position: relative;
}
.content-welcome-video-containers:hover .content-video-title-date-container {
  opacity: 1;
}

.content-video-title-date-container-welcome {
  color: #000;
  position: absolute;
  z-index: 10;
}

.content-welcome-image-large-error {
  width: 89.8%;
  height: auto;
}

.content-welcome-image-large-error-bottom {
  margin-top: 100px;
}

@media screen and (max-width: 991px) {
  .content-video-container-large {
    height: 50vh;
  }

  .content-video-title-date-container {
    opacity: 1;
  }

  .content-video-title-date-container {
    font-size: 18px;
  }
  .content-welcome-title {
    font-size: 30px;
  }
  .content-welcome-container-homepage {
    margin: 0 30px;
  }
  .content-welcome-image {
    height: 700px;
    margin-bottom: 30px;
  }

  .content-welcome-image-first-large {
    height: auto;
    width: 1000px;
    padding-right: 80px;
  }

  #bg {
    user-select: none;
    pointer-events: none;
  }

  .content-welcome-image-large-error {
    height: auto;
    width: 1000px;
    padding-right: 80px;
  }
}

@media screen and (max-width: 575px) {
  .content-welcome-image {
    height: 450px;
    margin-bottom: 30px;
  }
  .content-welcome-image-first-large {
    height: auto;
    width: 500px;
    padding-right: 80px;
  }
  .content-welcome-image-large-error {
    height: auto;
    width: 500px;
    padding-right: 80px;
  }
}
