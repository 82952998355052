.movie-main-container {
  background-color: var(--dark-color);
  color: var(--light-color);
  padding: 60px 40px 50px 40px;
}

.movie-video-container {
  display: flex;
  justify-content: center;
}

.movie-video {
  height: 104vh;
  width: 100%;
  border-radius: 30px;
}

@media screen and (min-width: 1600px) {
  .movie-video {
    height: 108vh;
  }
}

.movie-main-title {
  font-family: var(--base-font-family);
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 20px;
  font-size: 18px;
}

/* .movie-image {
  border-radius: 30px;
} */

.movie-image-container-small {
  margin: 20px 0px 20px 0px;
}

.movie-image-container-small-left {
  padding-right: 10px;
}

.movie-image-container-small-right {
  padding-left: 10px;
}

.movie-team-container {
  border-top: solid 4px var(--light-color);
  border-bottom: solid 4px var(--light-color);
  padding: 50px 20px 50px 0px;
  margin-top: 50px;
  font-family: var(--base-font-family);
  font-weight: 400;
  text-transform: uppercase;
}

.movie-description-container {
  padding: 50px 20px 20px 0px;
  font-family: var(--base-font-family);
}

.movie-team-title {
  font-size: 18px;
  margin-bottom: 40px;
  font-weight: 600;
}

.movie-team-text {
  font-size: 12px;
  font-family: transducer, sans-serif;
  text-transform: uppercase;
}

.movie-description-title {
  font-size: 18px;
  margin-bottom: 40px;
  font-weight: 600;
}

.movie-description-text {
  font-size: 12px;
  font-family: transducer, sans-serif;
}

@media screen and (max-width: 1000px) {
  .movie-main-container {
    padding: 60px 20px 50px 20px;
  }
  .movie-image-container-small-left {
    padding-right: 0px;
    margin-bottom: 0;
  }

  .movie-image-container-small-right {
    padding-left: 0px;
  }
  .movie-video {
    height: 50vh;
  }
}
