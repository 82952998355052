.pictures-slider {
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: black;
  padding-left: 10px;
  padding-right: 30px;
}

.pictures-slider-home {
  background-color: #cb1f1f00;
}

.pictures-grid-home {
  display: inline-grid;
  grid-auto-columns: calc(60vh / 3 * 2);
  grid-template-rows: repeat(1, 60vh);
  grid-auto-flow: column dense;
  grid-gap: 10px;
  margin-inline: 10px;
}

.pictures-item-home {
  height: 60vh;
}

.pictures-grid {
  /* display: grid; */
  display: inline-grid;
  grid-auto-columns: calc(40vh / 3 * 2);
  grid-template-rows: repeat(2, 40vh);
  grid-auto-flow: column dense;
  grid-gap: 10px;
  margin-inline: 10px;
}

.pictures-item {
  /* border-radius: 10px; */
  height: 40vh;
}

.pictures-item-large {
  grid-column: span 2;
}

.pictures-img-portrait {
  object-fit: cover;
  height: 100%;
  width: 100%;
  /* border-radius: 10px; */
}
