.nav-main-container {
  display: flex;
  justify-content: space-between;
}

.nav-home-btn {
  font-family: var(--h-font-family);
  text-transform: uppercase;
  position: fixed;
  color: var(--light-color);
  left: 20px;
  top: 15px;
  z-index: 10;
}

.nav-navigation-buttons {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s 0.4s;
  pointer-events: none;
  z-index: 4;
}

.nav-navigation-buttons.active {
  opacity: 1;
  pointer-events: all;
}

.nav-navigation-buttons-container {
  color: var(--light-color);
  font-family: var(--base-font-family);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.nav-navigation-link {
  transition: all 0.4s;
  border-radius: 10px;
  z-index: 20;
}

.nav-navigation-link:hover {
  transform: scale(0.9);
}

.nav-navigation-buttons-container:hover #c {
  opacity: 1;
}

#c {
  position: absolute;
  opacity: 0;
  transition: all 0.3s;
}

.nav-navigation-container {
  height: 35px;
  width: 35px;
  background-color: var(--light-color);
  border-radius: 30px;
  position: fixed;
  right: 20px;
  top: 15px;
  z-index: 5;
  transition: all 1.5s;
}

.nav-navigation-container-homepage {
  background-color: var(--dark-color);
}

.nav-navigation-container.active {
  background-color: var(--light-color);
}

.nav-navigation-background {
  width: 0;
  height: 0;
  top: -20px;
  right: -25px;
  transition: 1.5s ease;
  background-color: var(--dark-color);
  position: fixed;
  z-index: 3;
  border-radius: 100%;
}

.nav-navigation-background.active {
  width: 3200px;
  height: 3200px;
  transform: translate(25%, -50%);
}

.nav-cursor {
  position: fixed;
  z-index: 10;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--light-color);
  transform: translate(-50%, -50%);
  pointer-events: none;
  mix-blend-mode: difference;
  z-index: 100000;
}

.nav-home-page-btn {
  color: var(--dark-color);
}

@media screen and (max-width: 1200px) {
  .nav-navigation-buttons-container {
    font-size: 40px;
  }

  .content-btn-container {
    font-size: 12px;
  }

  .nav-cursor {
    display: none;
  }

  /* .nav-navigation-buttons {
    justify-content: flex-start;
    margin-left: 20px;
  } */
}

@media screen and (min-width: 1400px) {
  .nav-navigation-background.active {
    width: 4200px;
    height: 4200px;
    transform: translate(20%, -40%);
  }
}
